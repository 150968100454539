/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { SessionProvider } from "next-auth/react";
import NextNprogress from "nextjs-progressbar";
import "../styles/global.scss";
import Script from "next/script";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { hotjar } from "react-hotjar";
import AppUserContextProvider from "./context/userContext";


export default function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [host, setHost] = useState("");

  useEffect(() => {
    const handleRouteChange = (url) => {
      // Gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    setHost(window.location.hostname);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    hotjar.initialize(process.env.HOTJAR_ID);
  }, []);

  return (
    <>
      {host === "www.strideequity.com.au" && (
        <>
          <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.google_analytics}`}
          />
          <Script strategy="lazyOnload">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.google_analytics}', {
              page_path: window.location.pathname,
              });
          `}
          </Script>
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.google_tag_manager}');
      `}
          </Script>
        </>
      )}
      <SessionProvider session={pageProps.session}>
      <AppUserContextProvider>
        <NextNprogress
          color="#ED3724"
          startPosition={0.3}
          stopDelayMs={200}
          height="1"
          options={{ easing: 'ease', speed: 500, showSpinner: false }}
        />
        <Component {...pageProps} />
        </AppUserContextProvider>
      </SessionProvider>
    </>
  );
}
