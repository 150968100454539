import { createContext, useState } from "react";

export const AppUserContext = createContext({
  role: "investor",
  setRole: () => {},
});

const AppUserContextProvider = ({ children }) => {
  const [appUser, setAppUser] = useState({ role: "investor" });

  //set user role
  const setAppUserRole = (value) => setAppUser({ role: value });

  return (
    <AppUserContext.Provider value={{ appUser, setAppUser: setAppUserRole }}>
      {children}
    </AppUserContext.Provider>
  );
};

export default AppUserContextProvider;
